import React, { PureComponent } from 'react'
import { graphql, StaticQuery } from 'gatsby'
import Img from 'gatsby-image'

import Title from '../Title'
import Text from '../Text'
import Bullet from '../Bullet'
import './styles.css'

class HowItWorks extends PureComponent {
  render() {
    return (
      <div className="HowItWorks">
        <div className="HowItWorks-description">
          <Title>How We Do It</Title>
          <div className="HowItWorks-content">
            <Text>
              Keyruptive transforms and stores your crypto keys in a series of
              secure clouds. None of the clouds interact with one another and
              only you can recover the keys to access your assets.
            </Text>
            <ul className="HowItWorks-contentBullets">
              <div className="HotItWors-bulletContainer">
                <Bullet>
                  Your keys are never stored in a single device or location, to
                  keep them secure both digitally and physically.
                </Bullet>
              </div>
              <div className="HotItWors-bulletContainer">
                <Bullet>
                  Your keys get recovered and maintained in memory for only the
                  time that it takes to sign the transaction.
                </Bullet>
              </div>
            </ul>
          </div>
        </div>
        <div className="HowItWorks-schema">
          <Img
            fluid={this.props.data.schema.childImageSharp.fluid}
            alt="Keyruptive Schema"
          />
        </div>
      </div>
    )
  }
}

export default props => (
  <StaticQuery
    query={imagesQuery}
    render={data => <HowItWorks {...props} data={data} />}
  />
)

const imagesQuery = graphql`
  query {
    schema: file(relativePath: { eq: "components/HowItWorks/schema.png" }) {
      childImageSharp {
        fluid(maxWidth: 884) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
  }
`
