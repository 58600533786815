import React, { PureComponent } from 'react'
import posed, { PoseGroup } from 'react-pose'

import Title from '../../Title'
import Subtitle from '../../Subtitle'
import Text from '../../Text'
import './styles.css'
import Image from './image.svg'

const FeatureContent = posed.div({
  enter: { height: 'auto', opacity: 1, delay: 150 },
  exit: { height: '0%', opacity: 0 },
})

export default class Feature1 extends PureComponent {
  state = { isOpen: false }

  handleClick = () => this.setState({ isOpen: !this.state.isOpen })

  renderShowMore = () => (
    <FeatureContent
      key="2"
      className="Features-knowMore"
      onClick={this.handleClick}
    >
      <span>know more</span>
    </FeatureContent>
  )

  renderFullContent() {
    return (
      <FeatureContent key="1">
        <div className="Feature1-textContainer">
          <Subtitle>No single point of attack</Subtitle>
          <div className="Feature1-contentText">
            <Text>
              Since we never store them in one single device or location, we are
              able to insolate your funds from a compromised system.
            </Text>
          </div>
        </div>
        <div className="Feature1-textContainer">
          <Subtitle>No worries about losing your funds</Subtitle>
          <div className="Feature1-contentText">
            <Text>
              While each storage site backs up your data out-of-the-box, the
              traditional mnemonic mechanism allows you to recover your keys in
              the unlikely event of a site failure.
            </Text>
          </div>
        </div>
      </FeatureContent>
    )
  }

  render() {
    return (
      <div className="Feature1">
        <div className="Feature1-description">
          <div className="Feature1-title">
            <Title>No Hardware Wallet Needed</Title>
          </div>
          <div className="Feature1-content">
            <div className="Feature1-text">
              <div className="Feature1-textContainer">
                <div className="Feature1-contentText">
                  <Text alt={!this.state.isOpen}>
                    You don’t need a hardware wallet to be safe. Your keys are
                    stored across separate data clouds, so that is impossible to
                    get hold of your keys even with complete access to one of
                    the locations.
                  </Text>
                </div>
              </div>
              <PoseGroup>
                {this.state.isOpen
                  ? this.renderFullContent()
                  : this.renderShowMore()}
              </PoseGroup>
            </div>
            <div className="Feature1-image">
              <img src={Image} alt="Feature" />
            </div>
          </div>
        </div>
      </div>
    )
  }
}
