import React, { PureComponent } from 'react'

import './styles.css'

export default class Bullet extends PureComponent {
  render() {
    return (
      <li className="Bullet">
        <div className="Bullet-container">
          <span className="Bullet-dot" />
        </div>
        <div className="Bullet-text">{this.props.children}</div>
      </li>
    )
  }
}
