import React, { PureComponent } from 'react'

import Subtitle from '../Subtitle'
import Form from '../MailchimpForm'
import Input from '../Input'
import './styles.css'

export default class Join extends PureComponent {
  render() {
    return (
      <div id="join" className="Join">
        <div className="Join-background">
          <div className="Join-image" />
          <div className="Join-circle" />
        </div>
        <div className="Join-container">
          <Subtitle>Your crypto funds</Subtitle>
          <h2 className="Join-title">
            secure as a hardware wallet. Accessible everywhere, anytime.
          </h2>
          <div className="Join-buttonContainer">
            <div>
              <Subtitle>Coming soon. Stay up to date!</Subtitle>
              <Form className="Join-button">
                <Input type="email" placeholder="Your email" button alt>
                  Join
                </Input>
              </Form>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
