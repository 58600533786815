import React, { PureComponent } from 'react'

import Subtitle from '../../Subtitle'
import Button from '../../Button'
import './styles.css'

export default class FeatureButton extends PureComponent {
  render() {
    return (
      <div className="FeatureButton">
        <div className="FeatureButton-wrapper">
          <Subtitle>Our Alpha program has ended and we are now working from the great feedback we got. Subscribe to our newsletter and stay up to date!</Subtitle>
          <div className="FeatureButton-buttonsContainer">
            <div className="FeatureButton-buttonContainer">
              <Button href="#join">
                Join Keyruptive
              </Button>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
