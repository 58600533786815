import React, { PureComponent } from 'react'
import { StaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'

import './styles.css'

import Logo from '../Logo'
import Form from '../MailchimpForm'
import Title from '../Title'
import Subtitle from '../Subtitle'
import Text from '../Text'
import Input from '../Input'

class Hero extends PureComponent {
  render() {
    return (
      <div className="Hero">
        <div className="Hero-contentWrapper">
          <div className="Hero-logoWrapper">
            <Logo />
          </div>
          <div className="Hero-logoWrapperDesktop">
            <Logo textOnly />
          </div>
          <div className="Hero-title">
            <Title>Security Meets Convenience</Title>
          </div>
          <div className="Hero-description">
            <Text>
              Keyruptive offers the same security levels of hardware wallets but
              with the convenience of a home banking app. Keep your crypto funds
              safe, but always within reach and easy to manage.
            </Text>
          </div>
          <div className="Hero-cta">
            <div className="Hero-title">
              <Subtitle>Coming soon. Stay up to date!</Subtitle>
            </div>
            <div className="Hero-button">
              <Form>
                <Input type="email" placeholder="Your email" button>
                  Join
                </Input>
              </Form>
            </div>
          </div>
        </div>
        <div className="Hero-imageWrapper">
          <div className="Hero-desktopImage">
            <Img
              fluid={this.props.data.imageDesktop.childImageSharp.fluid}
              alt="Hero"
            />
          </div>
          <div className="Hero-mobileImage">
            <Img
              fluid={this.props.data.imageMobile.childImageSharp.fluid}
              alt="Hero"
            />
          </div>
          <div className="Hero-quoteWrapper" />
        </div>
      </div>
    )
  }
}

export default props => (
  <StaticQuery
    query={imagesQuery}
    render={data => <Hero {...props} data={data} />}
  />
)

const imagesQuery = graphql`
  query {
    imageDesktop: file(relativePath: { eq: "components/Hero/desktop.png" }) {
      childImageSharp {
        fluid(maxWidth: 1584) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    imageMobile: file(relativePath: { eq: "components/Hero/mobile.png" }) {
      childImageSharp {
        fluid(maxWidth: 904) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
  }
`
