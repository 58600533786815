import React, { PureComponent } from 'react'

import Title from '../Title'
import PartnerLogo from '../PartnerLogo'

import './styles.css'

export default class Partnerships extends PureComponent {
  render() {
    return (
      <div className="Partnerships">
        <div className="Partnerships-title">
          <Title>Partnerships</Title>
        </div>
        <div className="Partnerships-logos">
          <a
            href="https://safecloudtech.com/"
            className="Partnerships-logoContainer"
            target="_blank"
            rel="noopener noreferrer"
          >
            <PartnerLogo name="safecloudtech" />
          </a>
          <a
            href="https://subvisual.com/"
            className="Partnerships-logoContainer"
            target="_blank"
            rel="noopener noreferrer"
          >
            <PartnerLogo name="subvisual" />
          </a>
          <a
            href="https://www.inesctec.pt/"
            className="Partnerships-logoContainer"
            target="_blank"
            rel="noopener noreferrer"
          >
            <PartnerLogo name="inesctec" />
          </a>
        </div>
      </div>
    )
  }
}
