import React, { PureComponent } from 'react'

import SafeCloudTech from './safecloudtech.svg'
import Subvisual from './subvisual.svg'
import InescTec from './inesctec.svg'

const icons = {
  safecloudtech: SafeCloudTech,
  subvisual: Subvisual,
  inesctec: InescTec,
}

var classNames = require('classnames')

export default class PartnerLogo extends PureComponent {
  render() {
    const name = classNames({
      safecloudtech: this.props.name === 'safecloudtech',
      subvisual: this.props.name === 'subvisual',
      inesctec: this.props.name === 'inesctec',
    })

    return (
      <img
        className={`PartnerLogo PartnerLogo-${name}`}
        src={icons[name]}
        alt={`${name} logo`}
      />
    )
  }
}
