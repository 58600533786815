import React, { PureComponent } from 'react'

import './styles.css'
import Text from '../Text'

export default class Avatar extends PureComponent {
  render() {
    return (
      <div className="Avatar">
        {this.props.children}
        <div className="Avatar-name">
          <Text>{this.props.name}</Text>
        </div>
        {this.props.jobTitle && (
          <div className="Avatar-jobTitle">
            <Text>{this.props.jobTitle}</Text>
          </div>
        )}
      </div>
    )
  }
}
