import React, { PureComponent } from 'react'
import posed, { PoseGroup } from 'react-pose'
import { graphql, StaticQuery } from 'gatsby'
import Img from 'gatsby-image'

import Title from '../../Title'
import Subtitle from '../../Subtitle'
import Text from '../../Text'
import './styles.css'

const FeatureContent = posed.div({
  enter: { height: 'auto', opacity: 1, delay: 150 },
  exit: { height: '0%', opacity: 0 },
})

class Feature2 extends PureComponent {
  constructor(props) {
    super(props)
    this.state = { isOpen: false }
  }

  handleClick = () => this.setState({ isOpen: !this.state.isOpen })

  renderShowMore = () => (
    <FeatureContent
      key="2"
      className="Features-knowMore"
      onClick={this.handleClick}
    >
      <span>know more</span>
    </FeatureContent>
  )

  renderFullContent() {
    return (
      <FeatureContent key="1">
        <div className="Feature2-textContainer">
          <Subtitle>Only you can access your funds</Subtitle>
          <div className="Feature2-contentText">
            <Text>
              You should be in control of your crypto, not some third party.
              Take complete control and easily manage your digital assets from
              one single app.
            </Text>
          </div>
        </div>
        <div className="Feature2-textContainer">
          <Subtitle>Only available when you need them</Subtitle>
          <div className="Feature2-contentText">
            <Text>
              Your keys get recovered and maintained in memory for only the time
              that it takes to sign the transaction.
            </Text>
          </div>
        </div>
      </FeatureContent>
    )
  }

  render() {
    return (
      <div className="Feature2">
        <div className="Feature2-description">
          <div className="Feature2-content">
            <div className="Feature2-title">
              <Title>Only You Control Your Keys</Title>
            </div>
            <div className="Feature2-text">
              <div className="Feature2-textContainer">
                <div className="Feature2-contentText">
                  <Text alt={!this.state.isOpen}>
                    Your data is distributed across multiple independent
                    locations and only you have access to them all. That means
                    only you can recover your information and keys.
                  </Text>
                </div>
              </div>
              <PoseGroup>
                {this.state.isOpen
                  ? this.renderFullContent()
                  : this.renderShowMore()}
              </PoseGroup>
            </div>
          </div>
          <div className="Feature2-image">
            <Img
              fluid={this.props.data.image.childImageSharp.fluid}
              alt="Feature"
            />
          </div>
        </div>
      </div>
    )
  }
}

export default props => (
  <StaticQuery
    query={imagesQuery}
    render={data => <Feature2 {...props} data={data} />}
  />
)

const imagesQuery = graphql`
  query {
    image: file(
      relativePath: { eq: "components/Features/Feature2/image.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 650) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
  }
`
