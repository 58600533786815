import React, { PureComponent } from 'react'

import './styles.css'
import Title from '../Title'
import CurrencyIcon from '../CurrencyIcon'

export default class SupportedCurrencies extends PureComponent {
  render() {
    return (
      <div className="SupportedCurrencies">
        <div className="SupportedCurrencies-container">
          <div className="SupportedCurrencies-title">
            <Title>Supported Currencies</Title>
          </div>
          <div className="SupportedCurrencies-content">
            <div className="SupportedCurrencies-currency">
              <CurrencyIcon name="bitcoin" />
            </div>
            <div className="SupportedCurrencies-currency">
              <CurrencyIcon name="ethereum" />
            </div>
            <div className="SupportedCurrencies-currency">
              <CurrencyIcon name="utrust" />
            </div>
          </div>
        </div>
      </div>
    )
  }
}
