import React, { PureComponent } from 'react'

import './styles.css'

import Bitcoin from './icons/bitcoin.svg'
import Ethereum from './icons/ethereum.svg'
import Utrust from './icons/utrust.svg'

const coins = {
  bitcoin: {
    href: 'https://bitcoin.org',
    icon: Bitcoin,
  },
  ethereum: {
    href: 'https://ethereum.org',
    icon: Ethereum,
  },
  utrust: {
    href: 'https://utrust.com',
    icon: Utrust,
  },
}

var classNames = require('classnames')

export default class CurrencyIcon extends PureComponent {
  render() {
    const { name } = this.props

    const iconName = classNames({
      bitcoin: name === 'bitcoin',
      ethereum: name === 'ethereum',
      utrust: name === 'utrust',
    })

    return (
      <a href={coins[iconName].href} target="_blank" rel="noopener noreferrer">
        <img
          className="CurrencyIcon"
          src={coins[iconName].icon}
          alt={iconName}
        />
      </a>
    )
  }
}
