import React, { PureComponent } from 'react'
import { graphql, StaticQuery } from 'gatsby'
import Img from 'gatsby-image'

import './styles.css'
import Title from '../Title'
import Button from '../Button'
import Subtitle from '../Subtitle'
import Text from '../Text'
import Avatar from '../Avatar'
import SocialIcon from '../SocialIcon'

class Team extends PureComponent {
  render() {
    return (
      <div className="Team">
        <div className="Team-content">
          <div className="Team-title">
            <Title>Team</Title>
          </div>
          <div className="Team-mobile">
            <div className="Team-avatar">
              <Avatar name="Francisco Cruz" jobTitle="CPO">
                <Img
                  fluid={this.props.data.mobileCruz.childImageSharp.fluid}
                  alt="Francisco Cruz"
                />
              </Avatar>
              <div className="Team-socialIcons">
                <div className="Team-social">
                  <SocialIcon
                    name="linkedin"
                    href="https://www.linkedin.com/in/francisco-cruz-7408b95b"
                  />
                </div>
                <div className="Team-social">
                  <SocialIcon
                    name="dblp"
                    href="https://dblp.org/pers/hd/c/Cruz_0001:Francisco"
                  />
                </div>
              </div>
            </div>
            <div className="Team-avatar">
              <Avatar name="Francisco Maia" jobTitle="CEO">
                <Img
                  fluid={this.props.data.mobileMaia.childImageSharp.fluid}
                  alt="Francisco Maia"
                />
              </Avatar>
              <div className="Team-socialIcons">
                <div className="Team-social">
                  <SocialIcon
                    name="linkedin"
                    href="https://www.linkedin.com/in/falmeidamaia"
                  />
                </div>
                <div className="Team-social">
                  <SocialIcon
                    name="dblp"
                    href="https://dblp.org/pers/hd/m/Maia:Francisco"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="Team-desktop">
            <div className="Team-avatar">
              <Avatar name="Diogo Couto" jobTitle="Lead Developer">
                <Img
                  fluid={this.props.data.couto.childImageSharp.fluid}
                  alt="Diogo Couto"
                />
              </Avatar>
              <div className="Team-socialIcons">
                <div className="Team-social">
                  <SocialIcon name="github" href="https://github.com/djcouto" />
                </div>
                <div className="Team-social">
                  <SocialIcon
                    name="linkedin"
                    href="https://linkedin.com/in/djcouto"
                  />
                </div>
              </div>
            </div>
            <div className="Team-avatar">
              <Avatar name="Francisco Baila" jobTitle="Head of Design">
                <Img
                  fluid={this.props.data.baila.childImageSharp.fluid}
                  alt="Francisco Baila"
                />
              </Avatar>
              <div className="Team-socialIcons">
                <div className="Team-social">
                  <SocialIcon
                    name="behance"
                    href="https://www.behance.net/fcBaila"
                  />
                </div>
                <div className="Team-social">
                  <SocialIcon
                    name="medium"
                    href="https://medium.com/@fcBaila"
                  />
                </div>
              </div>
            </div>
            <div className="Team-avatar">
              <Avatar name="Francisco Cruz" jobTitle="CPO">
                <Img
                  fluid={this.props.data.cruz.childImageSharp.fluid}
                  alt="Francisco Cruz"
                />
              </Avatar>
              <div className="Team-socialIcons">
                <div className="Team-social">
                  <SocialIcon
                    name="linkedin"
                    href="https://www.linkedin.com/in/francisco-cruz-7408b95b"
                  />
                </div>
                <div className="Team-social">
                  <SocialIcon
                    name="dblp"
                    href="https://dblp.org/pers/hd/c/Cruz_0001:Francisco"
                  />
                </div>
              </div>
            </div>
            <div className="Team-avatar">
              <Avatar name="Francisco Maia" jobTitle="CEO">
                <Img
                  fluid={this.props.data.maia.childImageSharp.fluid}
                  alt="Francisco Maia"
                />
              </Avatar>
              <div className="Team-socialIcons">
                <div className="Team-social">
                  <SocialIcon
                    name="linkedin"
                    href="https://www.linkedin.com/in/falmeidamaia"
                  />
                </div>
                <div className="Team-social">
                  <SocialIcon
                    name="dblp"
                    href="https://dblp.org/pers/hd/m/Maia:Francisco"
                  />
                </div>
              </div>
            </div>
            <div className="Team-avatar">
              <Avatar name="Paula Rodrigues" jobTitle="Communication Manager">
                <Img
                  fluid={this.props.data.rodrigues.childImageSharp.fluid}
                  alt="Paula Rodrigues"
                />
              </Avatar>
              <div className="Team-socialIcons">
                <div className="Team-social">
                  <SocialIcon
                    name="linkedin"
                    href="https://linkedin.com/in/paula-rodrigues-8978886b"
                  />
                </div>
              </div>
            </div>
            <div className="Team-joinButton">
              <Button
                href="mailto:team@keyruptive.com?subject=Keyruptive, here I go!"
                target="_blank"
              >
                <div className="Team-joinContainer">
                  <div className="Team-joinContainerText">
                    <Text>Join us</Text>
                  </div>
                  <div className="Team-joinContainerText">
                    <Text>We are hiring</Text>
                  </div>
                </div>
              </Button>
            </div>
          </div>
        </div>
        <div className="Team-content">
          <div className="Team-title">
            <Subtitle>Advisors</Subtitle>
          </div>
          <div className="Team-mobile">
            <div className="Team-avatar">
              <Avatar name="Pascal Felber">
                <Img
                  fluid={this.props.data.mobileFelber.childImageSharp.fluid}
                  alt="Pascal Felber"
                />
              </Avatar>
              <div className="Team-socialIcons">
                <div className="Team-social">
                  <SocialIcon
                    name="linkedin"
                    href="https://www.linkedin.com/in/pascal-felber-95817b"
                  />
                </div>
                <div className="Team-social">
                  <SocialIcon
                    name="dblp"
                    href="https://dblp.org/pers/hd/f/Felber:Pascal"
                  />
                </div>
              </div>
            </div>
            <div className="Team-avatar">
              <Avatar name="Rui Oliveira">
                <Img
                  fluid={this.props.data.mobileOliveira.childImageSharp.fluid}
                  alt="Rui Oliveira"
                />
              </Avatar>
              <div className="Team-socialIcons">
                <div className="Team-social">
                  <SocialIcon
                    name="linkedin"
                    href="https://www.linkedin.com/in/rcmoliveira"
                  />
                </div>
                <div className="Team-social">
                  <SocialIcon
                    name="dblp"
                    href="https://dblp.org/pers/hd/o/Oliveira:Rui"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="Team-desktop">
            <div className="Team-avatar">
              <Avatar name="João Paulo">
                <Img
                  fluid={this.props.data.paulo.childImageSharp.fluid}
                  alt="João Paulo"
                />
              </Avatar>
              <div className="Team-socialIcons">
                <div className="Team-social">
                  <SocialIcon
                    name="linkedin"
                    href="https://www.linkedin.com/in/jtpaulo"
                  />
                </div>
                <div className="Team-social">
                  <SocialIcon
                    name="dblp"
                    href="https://dblp.org/pers/hd/p/Paulo:Jo=atilde=o"
                  />
                </div>
              </div>
            </div>
            <div className="Team-avatar">
              <Avatar name=" Pascal Felber">
                <Img
                  fluid={this.props.data.felber.childImageSharp.fluid}
                  alt="Pascal Felber"
                />
              </Avatar>
              <div className="Team-socialIcons">
                <div className="Team-social">
                  <SocialIcon
                    name="linkedin"
                    href="https://www.linkedin.com/in/pascal-felber-95817b"
                  />
                </div>
                <div className="Team-social">
                  <SocialIcon
                    name="dblp"
                    href="https://dblp.org/pers/hd/f/Felber:Pascal"
                  />
                </div>
              </div>
            </div>
            <div className="Team-avatar">
              <Avatar name="Roberto Machado">
                <Img
                  fluid={this.props.data.roberto.childImageSharp.fluid}
                  alt="Roberto Machado"
                />
              </Avatar>
              <div className="Team-socialIcons">
                <div className="Team-social">
                  <SocialIcon
                    name="linkedin"
                    href="https://www.linkedin.com/in/robertomachado"
                  />
                </div>
                <div className="Team-social">
                  <SocialIcon name="twitter" href="https://twitter.com/rmdgb" />
                </div>
              </div>
            </div>
            <div className="Team-avatar">
              <Avatar name="Rui Oliveira">
                <Img
                  fluid={this.props.data.oliveira.childImageSharp.fluid}
                  alt="Rui Oliveira"
                />
              </Avatar>
              <div className="Team-socialIcons">
                <div className="Team-social">
                  <SocialIcon
                    name="linkedin"
                    href="https://www.linkedin.com/in/rcmoliveira"
                  />
                </div>
                <div className="Team-social">
                  <SocialIcon
                    name="dblp"
                    href="https://dblp.org/pers/hd/o/Oliveira:Rui"
                  />
                </div>
              </div>
            </div>
            <div className="Team-avatar">
              <Avatar name="Valerio Schiavoni">
                <Img
                  fluid={this.props.data.valerio.childImageSharp.fluid}
                  alt="Valerio Schiavoni"
                />
              </Avatar>
              <div className="Team-socialIcons">
                <div className="Team-social">
                  <SocialIcon
                    name="linkedin"
                    href="https://www.linkedin.com/in/vschiavoni"
                  />
                </div>
                <div className="Team-social">
                  <SocialIcon
                    name="dblp"
                    href="https://dblp.org/pers/hd/s/Schiavoni:Valerio"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default props => (
  <StaticQuery
    query={imagesQuery}
    render={data => <Team {...props} data={data} />}
  />
)

const imagesQuery = graphql`
  query {
    mobileMaia: file(
      relativePath: { eq: "components/Team/images/mobile/maia.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 584, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    mobileCruz: file(
      relativePath: { eq: "components/Team/images/mobile/cruz.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 584, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    mobileFelber: file(
      relativePath: { eq: "components/Team/images/mobile/felber.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 584, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    mobileOliveira: file(
      relativePath: { eq: "components/Team/images/mobile/oliveira.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 584, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    maia: file(
      relativePath: { eq: "components/Team/images/desktop/maia.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 414, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    cruz: file(
      relativePath: { eq: "components/Team/images/desktop/cruz.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 414, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    baila: file(
      relativePath: { eq: "components/Team/images/desktop/baila.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 414, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    justo: file(
      relativePath: { eq: "components/Team/images/desktop/justo.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 414, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    couto: file(
      relativePath: { eq: "components/Team/images/desktop/couto.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 414, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    rodrigues: file(
      relativePath: { eq: "components/Team/images/desktop/rodrigues.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 414, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    oliveira: file(
      relativePath: { eq: "components/Team/images/desktop/oliveira.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 414, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    felber: file(
      relativePath: { eq: "components/Team/images/desktop/felber.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 414, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    paulo: file(
      relativePath: { eq: "components/Team/images/desktop/paulo.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 414, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    valerio: file(
      relativePath: { eq: "components/Team/images/desktop/valerio.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 414, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    roberto: file(
      relativePath: { eq: "components/Team/images/desktop/roberto.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 414, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
  }
`
