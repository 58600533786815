import React from 'react'

import Hero from '../components/Hero'
import Layout from '../components/Layout'
import HowItWorks from '../components/HowItWorks'
import Features from '../components/Features'
import SupportedCurrencies from '../components/SupportedCurrencies'
import Join from '../components/Join'
import Footer from '../components/Footer'
import Section from '../components/Section'
import Team from '../components/Team'
import Partnerships from '../components/Partnerships'

const IndexPage = () => {
  if (typeof window !== 'undefined') {
    // Make scroll behavior of internal links smooth
    require('smooth-scroll')('a[href*="#"]')
  }

  return (
    <Layout>
      <Section>
        <Hero />
      </Section>
      <Section>
        <HowItWorks />
      </Section>
      <Section>
        <Features />
      </Section>
      <Section>
        <SupportedCurrencies />
      </Section>
      <Section>
        <Join />
      </Section>
      <Section>
        <Team />
      </Section>
      <Section>
        <Partnerships />
      </Section>
      <Section>
        <Footer />
      </Section>
    </Layout>
  )
}

export default IndexPage
