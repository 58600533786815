import React, { PureComponent } from 'react'

import './styles.css'
import Background from './background.svg'
import Section from '../Section'
import Feature1 from './Feature1'
import Feature2 from './Feature2'
import Feature3 from './Feature3'
import FeatureButton from './FeatureButton'
export default class Features extends PureComponent {
  render() {
    return (
      <div className="Features">
        <div className="Features-background">
          <img src={Background} alt="Background" />
        </div>
        <div className="Features-sections">
          <Section>
            <Feature1 />
          </Section>
          <Section>
            <Feature2 />
          </Section>
          <Section>
            <FeatureButton />
          </Section>
          <Section>
            <Feature3 />
          </Section>
        </div>
      </div>
    )
  }
}
