import React, { PureComponent } from 'react'
import posed, { PoseGroup } from 'react-pose'
import { graphql, StaticQuery } from 'gatsby'
import Img from 'gatsby-image'

import Title from '../../Title'
import Text from '../../Text'
import Subtitle from '../../Subtitle'
import './styles.css'

const FeatureContent = posed.div({
  enter: { height: 'auto', opacity: 1, delay: 150 },
  exit: { height: '0%', opacity: 0 },
})

class Feature3 extends PureComponent {
  constructor(props) {
    super(props)
    this.state = { isOpenSend: false, isOpenStealth: false }

    this.handleClickSend = this.handleClickSend.bind(this)
    this.handleClickStealth = this.handleClickStealth.bind(this)
  }

  handleClickSend() {
    this.setState(state => ({
      isOpenSend: !state.isOpenSend,
    }))
  }

  handleClickStealth() {
    this.setState(state => ({
      isOpenStealth: !state.isOpenStealth,
    }))
  }

  render() {
    return (
      <div className="Feature3">
        <div className="Feature3-description">
          <div className="Feature3-descriptionWrapper">
            <div className="Feature3-title">
              <Title>Easy as Home Banking</Title>
            </div>
            <div className="Feature3-content">
              <Text>
                With keyruptive, managing your digital assets is just as easy as
                using your mobile banking app.
              </Text>
            </div>
          </div>
        </div>
        <div className="Feature3-imagesWrapper">
          <div className="Feature3-image">
            <div className="Feature3-imageTitle">
              <Subtitle>Send Funds</Subtitle>
            </div>
            <Img
              fluid={this.props.data.send.childImageSharp.fluid}
              alt="Feature"
            />
            <PoseGroup>
              {this.state.isOpenSend && (
                <FeatureContent key="1" className="Feature3-imageDescription">
                  <Text>
                    With Keyruptive there is no need to remember or c&p those
                    long addresses. Just use your contacts and Keyruptive will
                    handle address management for you.
                  </Text>
                </FeatureContent>
              )}
              {!this.state.isOpenSend && (
                <FeatureContent
                  key="2"
                  className="Features-knowMore"
                  onClick={this.handleClickSend}
                >
                  <span>know more</span>
                </FeatureContent>
              )}
            </PoseGroup>
          </div>
          <div className="Feature3-image">
            <div className="Feature3-imageTitle">
              <Subtitle>Stealth Mode</Subtitle>
            </div>
            <Img
              fluid={this.props.data.stealth.childImageSharp.fluid}
              alt="Feature"
            />
            <PoseGroup>
              {this.state.isOpenStealth && (
                <FeatureContent key="1" className="Feature3-imageDescription">
                  <Text>
                    Set your keyruptive wallet to stealth mode when you are in
                    public. Sensitive information will be hidden while
                    performance metrics and management features will still allow
                    you to handle your crypto at ease.
                  </Text>
                </FeatureContent>
              )}
              {!this.state.isOpenStealth && (
                <FeatureContent
                  key="2"
                  className="Features-knowMore"
                  onClick={this.handleClickStealth}
                >
                  <span>know more</span>
                </FeatureContent>
              )}
            </PoseGroup>
          </div>
        </div>
      </div>
    )
  }
}

export default props => (
  <StaticQuery
    query={imagesQuery}
    render={data => <Feature3 {...props} data={data} />}
  />
)

const imagesQuery = graphql`
  query {
    send: file(relativePath: { eq: "components/Features/Feature3/send.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 650) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    stealth: file(
      relativePath: { eq: "components/Features/Feature3/stealth.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 650) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
  }
`
